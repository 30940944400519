import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CartContext } from "../Cart/CartContext";
import ProductCarousel from "./Carousel/ProductCarousel";
import MobileSlider from "./MobileSlider/MobileSlider";
import ProductDetails from "./ProductDetails/ProductDetails";
import ButtonGroup from "./ButtonGroup/ButtonGroup";
import Accordion from "./Accordion/Accordion";
import Notification from "../Notification/Notification";
import "./ProductPage.css";

const products = [
  {
    id: 1,
    name: "FIRE T-SHIRT",
    description: "Description for FIRE T-SHIRT",
    images: [
      "/Static/ProductImgs/FIRE/fire_1.jpg",
      "/Static/ProductImgs/FIRE/fire_2.jpg",
      "/Static/ProductImgs/FIRE/fire_3.jpg",
      "/Static/ProductImgs/FIRE/fire_4.jpg",
    ],
    price: 2099,
  },
  {
    id: 2,
    name: "FLORAL T-SHIRT",
    description: "Description for FLORAL T-SHIRT",
    images: [
      "/Static/ProductImgs/FLORAL_SILDE_IMAGES/floral_1.jpg",
      "/Static/ProductImgs/FLORAL_SILDE_IMAGES/floral_2.jpg",
      "/Static/ProductImgs/FLORAL_SILDE_IMAGES/floral_3.jpg",
    ],
    price: 2099,
  },
  {
    id: 3,
    name: "LITM T-SHIRT",
    description: "Description for LITM T-SHIRT",
    images: [
      "/Static/ProductImgs/LIVE_IN_THE_MOMENT/LITM_1.jpg",
      "/Static/ProductImgs/LIVE_IN_THE_MOMENT/LITM_2.jpg",
      "/Static/ProductImgs/LIVE_IN_THE_MOMENT/LITM_3.jpg",
    ],
    price: 2099,
  },
  {
    id: 4,
    name: "PIGEON T-SHIRT",
    description: "Description for PIGEON T-SHIRT",
    images: [
      "/Static/ProductImgs/PIGEON/pigeon_1.jpg",
      "/Static/ProductImgs/PIGEON/pigeon_2.jpg",
      "/Static/ProductImgs/PIGEON/pigeon_3.jpg",
      "/Static/ProductImgs/PIGEON/pigeon_4.jpg",
    ],
    price: 2099,
  },
];
const sizes = ["XS", "S", "M", "L", "XL"];

const sizeChart = [
  { size: "XS", chest: 42, shoulder: 19.5, length: 28 },
  { size: "S", chest: 44, shoulder: 20, length: 28.5 },
  { size: "M", chest: 46, shoulder: 20.5, length: 29 },
  { size: "L", chest: 48, shoulder: 21, length: 29.5 },
  { size: "XL", chest: 50, shoulder: 21.5, length: 30 },
];

const ProductPage = () => {
  const { id } = useParams();
  const { addToCart, notification, closeNotification, setNotification } =
    useContext(CartContext);
  const product = products.find((p) => p.id === parseInt(id));

  useEffect(() => {
    document.title = product.name;
  }, [product.name]);

  // Scroll to top when the component is mounted (i.e., when a product page is opened)
  useEffect(() => {
    //window.scrollTo(0, 0);
    setNotification({ message: "", visible: false });
  }, [setNotification]);

  if (!product) return <div>Product not found</div>;

  const handleAddToCart = (size) => {
    addToCart(product, size);
  };

  return (
    <div className="product-page">
      <ProductCarousel images={product.images} />
      <MobileSlider images={product.images} />
      <ProductDetails product={product} />
      <ButtonGroup
        productId={product.id}
        productName={product.name}
        sizes={sizes}
        sizeChart={sizeChart}
        onAddToCart={(size) => handleAddToCart(size)}
      />
      <Accordion product={product} />
      <Notification
        message={notification.message}
        visible={notification.visible}
        onClose={closeNotification}
      />
    </div>
  );
};

export default ProductPage;

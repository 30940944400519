import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ButtonGroup.css";

const ButtonGroup = ({
  productId,
  productName,
  sizes = [],
  sizeChart = [],
  onAddToCart,
}) => {
  const [activeSize, setActiveSize] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  const handleSizeClick = (size) => {
    setActiveSize(size);
  };

  const handleButtonClick = (buttonType) => {
    setClickedButton(buttonType);

    setTimeout(() => {
      setClickedButton(null);
    }, 300);

    if (!activeSize) {
      setMessage("Please select a size.");
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    } else {
      const buyNowSummary = {
        items: [
          {
            id: productId,
            name: productName,
            size: activeSize,
            price: 2099,
            quantity: quantity,
          },
        ],
        totalAmount: 2099 * quantity,
        itemsCount: quantity,
      };
      if (buttonType === "buyNow") {
        // Navigate with product data including price and quantity to the address form
        // console.log("Buy Now Summary:", buyNowSummary);
        navigate("/address", {
          state: {
            isBuyNow: true, // Added buyNow flag
            cartSummary: buyNowSummary,
          },
        });
      } else if (buttonType === "addToCart") {
        onAddToCart(activeSize);
      }
    }
  };

  return (
    <>
      <div className="cont">
        {/* Size selector buttons */}
        <div className="btn-group d-flex justify-content-center mb-2 mt-3">
          {sizes.map((size) => (
            <button
              key={size}
              type="button"
              className={`btn btn-outline-dark btn-sz btn-light ${
                activeSize === size ? "active" : ""
              }`}
              onClick={() => handleSizeClick(size)}
            >
              {size}
            </button>
          ))}
          <button
            type="button"
            className="btn btn-link btn-customm"
            data-bs-toggle="modal"
            data-bs-target="#sizeChartModal"
          >
            SIZE CHART
          </button>
        </div>

        {/* Quantity selector */}
        {/* <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
          <label className="me-2">Quantity:</label>
          <select
            className="form-select form-select-sm"
            style={{ width: "auto" }}
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          >
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div> */}
      </div>

      {/* Size Chart Modal */}
      <div
        className="modal fade"
        id="sizeChartModal"
        tabIndex="-1"
        aria-labelledby="sizeChartModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="sizeChartModalLabel">
                Size Chart
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>SIZE</th>
                    <th>CHEST</th>
                    <th>SHOULDER</th>
                    <th>LENGTH</th>
                  </tr>
                </thead>
                <tbody>
                  {sizeChart.length > 0 ? (
                    sizeChart.map(({ size, chest, shoulder, length }) => (
                      <tr key={size}>
                        <td>{size}</td>
                        <td>{chest}</td>
                        <td>{shoulder}</td>
                        <td>{length}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No size chart available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Error message */}
      {message && (
        <div className="alert alert-warning text-center" role="alert">
          {message}
        </div>
      )}

      {/* Action buttons */}
      <div className="container mt-2 mb-2">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-auto d-grid mb-2 mb-md-0">
            <div className="button-wrapper">
              <button
                className={`btn btn-dark btn-custom-lg btn-d w-100 ${
                  clickedButton === "buyNow" ? "btn-clicked" : ""
                }`}
                onClick={() => handleButtonClick("buyNow")}
              >
                BUY NOW
              </button>
            </div>
          </div>
          <div className="col-12 col-md-auto d-grid">
            <div className="button-wrapper">
              <button
                className={`btn btn-light btn-custom-lg btn-od w-100 ${
                  clickedButton === "addToCart" ? "btn-clicked" : ""
                }`}
                onClick={() => handleButtonClick("addToCart")}
              >
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonGroup;

import React from 'react';

const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="hero-image-left">
                <div className="video-container">
                    <video 
                        src="https://res.cloudinary.com/dwbdylxpr/video/upload/v1733132949/Web_video_nsrtuy.mp4" 
                        autoPlay 
                        loop 
                        muted
                        playsInline
                        preload="auto"
                        className="hero-video"
                    />
                </div>
            </div>
            {/* Swipe up symbol */}
            <img 
                src="/Static/Symbols/swipe.png" 
                alt="Swipe Up" 
                className="swipe-up-symbol"
            />
        </div>
    );
};

export default HeroSection;

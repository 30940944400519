import React, { useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Checkmark } from "react-checkmark";
import "./SuccessPage.css";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, paymentId, awbNo } = location.state || {};

  // Track Facebook Purchase event only if we have valid state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Only track if we have orderId and paymentId (confirming it's a real purchase)
    if (location.state?.orderId && location.state?.paymentId) {
      if (window.fbq) {
        window.fbq('track', 'Purchase');
      }
    }
  }, [location.state?.orderId, location.state?.paymentId]); // Add dependencies

  // If someone tries to access success page directly without state, redirect to home
  if (!location.state) {
    return <Navigate to="/" replace />;
  }

  const handleContinueShopping = () => {
    navigate("/");
  };

  return (
    <div className="success-page">
      <h1>Thank You for Shopping with ATNATIC!</h1>
      <p>
        Your order was successful, and a confirmation email is on its way to
        your inbox. We can't wait for you to rock your new product.
      </p>
      {orderId && <p>Order ID: {orderId}</p>}
      {paymentId && <p>Payment ID: {paymentId}</p>}
      {awbNo && <p>Track your Order using AWB Number: {awbNo}</p>}
      <div className="success-checkmark">
        <Checkmark size="large" color="#28a745" />
      </div>

      <button
        className="continue-shopping-btn btn btn-dark"
        onClick={handleContinueShopping}
      >
        Continue Shopping
      </button>
    </div>
  );
};

export default SuccessPage;

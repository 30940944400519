import React, { useState } from 'react';
import './Accordion.css';

const Accordion = ({ product }) => {
  const [openSection, setOpenSection] = useState(null);

  const handleToggle = (section) => {
    setOpenSection((prev) => (prev === section ? null : section));
  };

  const getSectionsForProduct = (product) => {
    switch (product.id) {
      case 1: // FIRE T-SHIRT
        return [
          {
            title: 'PRODUCT DETAILS',
            content: ['100% COTTON', 'WEIGHT - 250 GSM', 'PUFF PRINT', 'BIO WASH', 'SILICON WASH']
          },
          {
            title: 'DESCRIPTION',
            content: 'THE FIRE T-SHIRT IS CRAFTED WITH A UNIQUE PUFF PRINT AND A PREMIUM SILICON WASH FINISH, OFFERING A DISTINCTIVE BLEND OF STYLE AND DURABILITY. DESIGNED FOR STREETWEAR ENTHUSIASTS.'
          },
          {
            title: 'RETURN & EXCHANGE POLICY',
            content: ['THE PRODUCT DOES NOT FIT.', 'THE PRODUCT IS DEFECTIVE.', 'PARTS OR ACCESSORIES OF THE PRODUCT ARE MISSING.']
          },
          {
            title: 'CARE & MAINTENANCE',
            content: ['DO NOT IRON ON PRINT', 'WASH INSIDE OUT', 'DO NOT BLEACH', 'WASH COLD']
          }
        ];
        
      case 2: // FLORAL T-SHIRT
        return [
          {
            title: 'PRODUCT DETAILS',
            content: ['100% COTTON', 'WEIGHT - 250 GSM', 'HIGH-DENSITY PRINT', 'BIO WASH', 'SILICON WASH']
          },
          {
            title: 'DESCRIPTION',
            content: 'THE FLORAL T-SHIRT OFFERS A BLEND OF QUALITY AND STYLE WITH A BIO-WASH, MAKING IT SOFT AND COMFORTABLE FOR EVERYDAY WEAR.'
          },
          {
            title: 'RETURN & EXCHANGE POLICY',
            content: ['THE PRODUCT DOES NOT FIT.', 'THE PRODUCT IS DEFECTIVE.', 'PARTS OR ACCESSORIES OF THE PRODUCT ARE MISSING.']
          },
          {
            title: 'CARE & MAINTENANCE',
            content: ['DO NOT IRON ON PRINT', 'WASH INSIDE OUT', 'DO NOT BLEACH', 'WASH COLD']
          }
        ];
        
      case 3: // LITM T-SHIRT
        return [
          {
            title: 'PRODUCT DETAILS',
            content: ['100% COTTON', 'WEIGHT - 250 GSM', 'HIGH-DENSITY SCREEN PRINT', 'BIO WASH', 'SILICON WASH']
          },
          {
            title: 'DESCRIPTION',
            content: 'THE LITM T-SHIRT IS CRAFTED FROM PREMIUM-QUALITY COTTON AND FEATURES A STRIKING PUFF PRINT DESIGN, MAKING IT PERFECT FOR A BOLD AND TRENDY LOOK.'
          },
          {
            title: 'RETURN & EXCHANGE POLICY',
            content: ['THE PRODUCT DOES NOT FIT.', 'THE PRODUCT IS DEFECTIVE.', 'PARTS OR ACCESSORIES OF THE PRODUCT ARE MISSING.']
          },
          {
            title: 'CARE & MAINTENANCE',
            content: ['DO NOT IRON ON PRINT', 'WASH INSIDE OUT', 'DO NOT BLEACH', 'WASH COLD']
          }
        ];
        
      case 4: // PIGEON T-SHIRT
        return [
          {
            title: 'PRODUCT DETAILS',
            content: ['100% COTTON', 'WEIGHT - 250 GSM', 'PUFF PRINT', 'BIO WASH', 'SILICON WASH']
          },
          {
            title: 'DESCRIPTION',
            content: 'THE PIGEON T-SHIRT, MADE FROM DURABLE COTTON, FEATURES A HYPER-REALISTIC PUFF PRINT DESIGN THAT BLENDS COMFORT WITH A CONTEMPORARY EDGE, MAKING IT PERFECT FOR ANY OCCASION.'
          },
          {
            title: 'RETURN & EXCHANGE POLICY',
            content: ['THE PRODUCT DOES NOT FIT.', 'THE PRODUCT IS DEFECTIVE.', 'PARTS OR ACCESSORIES OF THE PRODUCT ARE MISSING.']
          },
          {
            title: 'CARE & MAINTENANCE',
            content: ['DO NOT IRON ON PRINT', 'WASH INSIDE OUT', 'DO NOT BLEACH', 'WASH COLD']
          }
        ];
        
      default:
        return [];
    }    
  };

  const sections = getSectionsForProduct(product);

  return (
    <div className="container mt-2">
      <div className="row">
        {sections.map((section, index) => (
          <div key={index} className="col-lg-4 col-12">
            <div className="card">
              <div className="card-header" id={`heading${index}`}>
                <h5 className="mb-0">
                  <button
                    className="btn btn-link btn-linkk d-flex justify-content-between align-items-center"
                    onClick={() => handleToggle(`collapse${index}`)}
                  >
                    {section.title}
                    <span className="toggle-icon">
                      {openSection === `collapse${index}` ? '-' : '+'}
                    </span>
                  </button>
                </h5>
              </div>

               <div
                 id={`collapse${index}`}
                 className={`collapse ${openSection === `collapse${index}` ? 'show' : ''}`}
                 aria-labelledby={`heading${index}`}
               >
                <div className="card-body">
                  {Array.isArray(section.content) ? (
                    <ul className="list-styled">
                      {section.content.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{section.content}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;

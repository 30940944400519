// src/pages/NotFound.js
import React from 'react';

function NotFound() {
    return (
        <div style={{ textAlign: 'center', padding: '50px' ,paddingTop:'200px'}}>
            <h1>404</h1>
            <p>Oops! The page you're looking for does not exist.</p>
            <a href="/" style={{ color: 'blue' }}>Go back to Home</a>
        </div>
    );
}

export default NotFound;

import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import "./Address.css";
import { CartContext } from "../Cart/CartContext";

const AddressForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems } = useContext(CartContext);

  // State to manage loading
  const [loading, setLoading] = useState(false);

  // Process cart items only if we're not in buy-now mode
  const cartItemsWithSubtotal = cartItems.map((item) => ({
    id: item.id,
    name: item.name,
    size: item.size,
    price: item.price,
    quantity: item.quantity,
    subtotal: item.price * item.quantity,
  }));

  const cartTotal = cartItemsWithSubtotal.reduce(
    (sum, item) => sum + item.subtotal,
    0
  );

  // Early validation of navigation state
  const navigationState = location.state;
  // console.log("Navigation State:", navigationState);
  // Handle Buy Now case
  let checkoutItems = [];
  let totalAmount = 0;

  if (navigationState?.isBuyNow && navigationState.cartSummary?.items) {
    // For Buy Now flow
    checkoutItems = navigationState.cartSummary.items;
    totalAmount = navigationState.cartSummary.totalAmount;
  } else {
    // For normal cart checkout flow
    checkoutItems = cartItemsWithSubtotal;
    totalAmount = cartTotal;
  }

  // console.log("Checkout Configuration:", {
  //   isBuyNow: navigationState?.isBuyNow,
  //   checkoutItems,
  //   totalAmount,
  // });

  const [showLandmarkField, setShowLandmarkField] = useState(false);
  const [showFreeShipping, setShowFreeShipping] = useState(true);
  const [formData, setFormData] = useState({
    pincode: "",
    city: "",
    state: "",
    fullName: "",
    phone: "",
    email: "",
    houseNo: "",
    area: "",
    landmark: "",
  });
  const [errors, setErrors] = useState({});

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  const toggleLandmarkField = () => setShowLandmarkField(!showLandmarkField);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.fullName) newErrors.fullName = "Full name is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.houseNo) newErrors.houseNo = "House number is required";
    if (!formData.area) newErrors.area = "Area is required";

    setErrors(newErrors);
    setShowFreeShipping(Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };

  const handleProceedClick = () => {
    if (validateForm()) {
      handleRazorpayPayment();
    }
  };

  const handleRazorpayPayment = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.atnatic.com/api/order", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items: checkoutItems.map((item) => ({
            productId: item.id,
            productName: item.name,
            quantity: item.quantity,
            price: item.price,
            size: item.size,
          })),
          pincode: formData.pincode,
          city: formData.city,
          state: formData.state,
          fullName: formData.fullName,
          phone: formData.phone,
          email: formData.email,
          houseNo: formData.houseNo,
          area: formData.area,
          landmark: formData.landmark,
        }),
      });

      const data = await response.json();
      const { amount, currency } = data;

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount,
        currency,
        name: "Atnatic",
        image: "",
        order_id: data.razorpayOrderId,
        handler: async function (response) {
          const { razorpay_payment_id: paymentId } = response;

          setLoading(true);
          const paymentCompleteResponse = await fetch(
            "https://api.atnatic.com/api/order/payment-complete",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                orderId: data.orderId,
                razorpayOrderId: data.razorpayOrderId,
                paymentId,
              }),
            }
          );
          const paymentCompleteData = await paymentCompleteResponse.json();
          // console.log("Payment Complete Response:", paymentCompleteData);
          // console.log("Payment Complete Data:", paymentCompleteData.data);
          const awbNo = paymentCompleteData.data.awbNo;
          // console.log("AWB No:", awbNo);

          navigate("/success", {
            state: {
              orderId: data.orderId,
              paymentId,
              awbNo,
            },
          });
        },
        prefill: {
          name: formData.fullName,
          email: formData.email,
          contact: formData.phone,
        },
        notes: {
          address: `${formData.houseNo}, ${formData.area}, ${formData.city}, ${formData.state}, ${formData.pincode}`,
          // quantity: productDetails.quantity,
          // price: productDetails.price,
        },
        theme: { color: "#000000", background_color: "#ffffff" },
        modal: { backdropclose: false, escape: false },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error in payment:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <div className="address-form">
        <h2 className="h2-address">ADD DELIVERY ADDRESS</h2>
        {loading ? (
          <div className="loading-spinner" style={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '20%',
          }}>
            <ClipLoader
              color="#000000"
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
            />
          </div>
        ) : (
          <form>
            {/* Address Form Inputss */}
            <div className="form-group">
              <input
                type="text"
                name="fullName"
                placeholder="Full name"
                value={formData.fullName}
                onChange={handleChange}
              />
              {errors.fullName && (
                <span className="error">{errors.fullName}</span>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="phone"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="form-row">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
              />
              {errors.city && <span className="error">{errors.city}</span>}
              <select name="state" value={formData.state} onChange={handleChange}>
                <option value="" disabled>
                  Select State
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors.state && <span className="error">{errors.state}</span>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="pincode"
                placeholder="Pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
              {errors.pincode && <span className="error">{errors.pincode}</span>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="houseNo"
                placeholder="House no / Building / Apartment"
                value={formData.houseNo}
                onChange={handleChange}
              />
              {errors.houseNo && <span className="error">{errors.houseNo}</span>}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="area"
                placeholder="Area, Sector, Street, Village"
                value={formData.area}
                onChange={handleChange}
              />
              {errors.area && <span className="error">{errors.area}</span>}
            </div>
            <div className="optional-group">
              <div className="add-landmark" onClick={toggleLandmarkField}>
                + Add landmark (optional)
              </div>
              {showLandmarkField && (
                <div className="form-group">
                  <input
                    type="text"
                    name="landmark"
                    placeholder="Landmark"
                    value={formData.landmark}
                    onChange={handleChange}
                  />
                </div>
              )}
              {showFreeShipping && (
                <div className="free-shipping-label">
                  <span className="icon"></span> Free Shipping{" "}
                  <span className="free-badge">FREE</span>
                </div>
              )}
              <div className="btn btn-dark" onClick={handleProceedClick}>
                PROCEED
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddressForm;

// src/pages/ExchangePolicy.js
import React from 'react';
import './ExchangePolicy.css';  // Ensure this file is created for the styles

const ExchangePolicy = () => {
    return (
        <div className="exchange-policy-container">
            <h2 className="policy-title">REFUND & EXCHANGE POLICY</h2>

            <h2 className="section-title">POLICY APPLICABILITY</h2>
            <p className="policy-text">
                BY USING THE ATNATIC EXCHANGES PORTAL AND/OR PLACING A REQUEST TO PURCHASE ANY PRODUCT(S) THROUGH THE PORTAL, YOU AGREE TO ADHERE TO THE TERMS OUTLINED IN THIS POLICY WITHOUT CHANGES. IF YOU DISAGREE WITH ANY OF THESE TERMS, WE ADVISE AGAINST TRANSACTING ON ATNATIC.COM.
            </p>
            <p className="policy-text">
                PLEASE NOTE THAT THE TERMS GOVERNING RETURNS, REFUNDS, OR CANCELLATIONS OF ORDERS ON ATNATIC.COM MAY BE UPDATED FROM TIME TO TIME. IT IS RECOMMENDED TO REVIEW THE POLICY EACH TIME YOU USE THE PORTAL TO ENSURE YOU ARE AWARE OF THE APPLICABLE TERMS AND CONDITIONS.
            </p>

            <h2 className="section-title">RETURN AND EXCHANGE PROCESS</h2>
            <p className="policy-text">
                WE OFFER RETURNS AND EXCHANGES WITHIN 7 DAYS OF THE DELIVERY DATE. ONCE YOUR REQUEST IS APPROVED, YOU WILL RECEIVE A NOTIFICATION VIA EMAIL. OUR LOGISTICS PARTNER WILL COLLECT THE ORDER WITHIN 24-48 HOURS. A MAXIMUM OF TWO PICK-UP ATTEMPTS WILL BE MADE. IF THE COURIER IS UNABLE TO COLLECT THE SHIPMENT, YOU WILL NEED TO SEND IT TO THE WAREHOUSE YOURSELF. REVERSE PICK-UP IS SUBJECT TO THE AVAILABILITY OF SERVICE IN YOUR AREA’S PINCODE.
            </p>
            <p className="policy-text">
                ONCE YOUR ORDER REACHES THE WAREHOUSE, IT WILL GO THROUGH A QUALITY CHECK (QC). AFTER THE QC IS PASSED, YOUR EXCHANGE/RETURN WILL BE PROCESSED. ESTIMATED DELIVERY FOR EXCHANGES IS 7-10 WORKING DAYS.
            </p>
            <p className="policy-text">
                IF A SIZE EXCHANGE CANNOT BE COMPLETED DUE TO STOCK LIMITATIONS, WE WILL EITHER OFFER AN ALTERNATIVE PRODUCT.
            </p>
            <p className="policy-text">
                FOR ALL RETURN ORDERS, REFUNDS WILL BE GIVEN IN THE FORM OF A CREDIT NOTE ONCE THE PRODUCT PASSES QC. THIS CREDIT NOTE WILL BE SENT TO YOUR REGISTERED EMAIL ADDRESS. PLEASE ENSURE PRODUCTS ARE RETURNED IN THE SAME CONDITION THEY WERE SHIPPED. IF PRODUCTS ARE RETURNED IN POOR CONDITION OR APPEAR TO BE WORN, THE RETURN/EXCHANGE WILL NOT BE PROCESSED.
            </p>
            <p className="policy-text">
                FOR FURTHER ASSISTANCE, YOU CAN EMAIL US WITH YOUR NAME AND ORDER ID AT ORDERSUPPORT@ATNATIC.COM. WE ARE AVAILABLE MONDAY-SATURDAY FROM 11:00 AM TO 7:00 PM. ALL PENDING QUERIES WILL BE PRIORITIZED AND ADDRESSED WITHIN 24 HOURS.
            </p>

            <h2 className="section-title">ORDER CANCELLATIONS</h2>
            <p className="policy-text">
                CANCELLATIONS ARE ONLY AVAILABLE FOR CASH ON DELIVERY (COD) ORDERS AND CAN ONLY BE PROCESSED IF THE ORDER HAS NOT BEEN DISPATCHED FROM OUR WAREHOUSE. PREPAID ORDERS ARE FINAL AND NON-REFUNDABLE.
            </p>

            <h2 className="section-title">EXCHANGE/RETURN OF PRODUCTS</h2>
            <p className="policy-text">
                YOU MAY REQUEST AN EXCHANGE OR RETURN FOR THE FOLLOWING REASONS:
            </p>
            <ul className="policy-list">
                <li>THE PRODUCT DOES NOT FIT.</li>
                <li>BOTH THE PRODUCT AND THE SHIPPING PACKAGE HAVE BEEN DAMAGED.</li>
                <li>THE PRODUCT IS DEFECTIVE.</li>
                <li>PARTS OR ACCESSORIES OF THE PRODUCT ARE MISSING.</li>
            </ul>
            <p className="policy-text">
                TO BE ELIGIBLE FOR EXCHANGE OR RETURN, THE PRODUCT(S) MUST MEET THE FOLLOWING CONDITIONS:
            </p>
            <ul className="policy-list">
                <li>THE PRODUCT HAS NOT BEEN WORN, CLEANED, OR TAMPERED WITH.</li>
                <li>THE BRAND TAGS, ORIGINAL PACKAGING, AND ACCOMPANYING ACCESSORIES MUST BE INTACT AND UNALTERED.</li>
                <li>THE PRODUCT MUST NOT HAVE BEEN ALTERED, UNLESS IT IS A VENDOR DEFECT.</li>
                <li>THE RETURN MUST BE INITIATED AGAINST THE ORDER IN WHICH THE PRODUCT WAS PURCHASED, AND THE PRODUCT MUST MATCH OUR RECORDS.</li>
                <li>THE PRODUCT MUST NOT HAVE ANY FOUL ODORS, STAINS, DENTS, SCRATCHES, TEARS, OR OTHER DAMAGE.</li>
                <li>PRODUCTS BOUGHT AS A SET MUST BE RETURNED/EXCHANGED AS A COMPLETE SET.</li>
                <li>PRODUCTS MUST BE IN AN UNUSED, UNDAMAGED, UNWASHED, AND SALEABLE CONDITION.</li>
                <li>WE MUST BE SATISFIED THAT THE PRODUCT HAS NOT BEEN RENDERED DEFECTIVE OR UNUSABLE.</li>
            </ul>
            <p className="policy-text">
                RETURNS ARE FACILITATED THROUGH OUR REVERSE-LOGISTICS PARTNERS. ONCE A RETURN REQUEST IS PLACED ON ATNATIC.COM AND ACKNOWLEDGED, OUR REVERSE-LOGISTICS PARTNER WILL CONTACT YOU TO COLLECT THE PRODUCT.
            </p>
            <p className="policy-text">
                WE RESERVE THE RIGHT TO REJECT ANY EXCHANGE/RETURN REQUEST IF THE PRODUCT DOES NOT MEET THE CONDITIONS OUTLINED ABOVE.
            </p>

            <h2 className="section-title">CUSTOMER SUPPORT</h2>
            <p className="policy-text">
                FOR ANY CONCERNS REGARDING RETURNS OR CANCELLATIONS, PLEASE CONTACT OUR CUSTOMER SUPPORT TEAM AT:
            </p>
            <p className="policy-text">
                <strong>CALL:</strong> +91 9988349333<br />
                <strong>EMAIL:</strong> contact@atnatic.com
            </p>
        </div>
    );
};

export default ExchangePolicy;

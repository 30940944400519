import React from 'react';

const MaintenancePage = () => {
    return (
        <div style={{ 
            position: 'relative', 
            height: '100vh', 
            overflow: 'hidden', 
            margin: 0 
        }}>
            <video 
                style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100vw', 
                    height: '100vh', 
                    objectFit: 'cover', 
                    objectPosition: 'center center', 
                    zIndex: -1, 
                    pointerEvents: 'none', 
                }} 
                autoPlay 
                loop 
                muted 
            >
                <source src="https://res.cloudinary.com/dwbdylxpr/video/upload/v1733132949/Web_video_nsrtuy.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div style={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                color: 'white', 
                textAlign: 'center', 
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                width: '100%',
                height: '100%',
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            
            }}>
                <img 
                    src="/Static/Models/Live_png.png" 
                    alt="Live Soon" 
                    style={{ 
                        maxWidth: '100%', 
                        maxHeight: '100%', 
                    }} 
                />
            </div>
        </div>
    );
};

export default MaintenancePage; 
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./TrackOrder.css";

const stages = [
  { id: 1, name: "Order Placed", description: "Your shipment has been booked", status: ["Online shipment booked"] },
  { id: 2, name: "Packed", description: "Shipment has arrived", status: ["SHIPMENT ARRIVED"] },
  { id: 3, name: "Shipped", description: "Shipment is further connected", status: ["SHIPMENT FURTHER CONNECTED", "SHIPMENT ARRIVED AT HUB","SHIPMENT ARRIVED","Shipment Out For Delivery"] },
  { id: 4, name: "Delivered", description: "Shipment delivered", status: ["SHIPMENT DELIVERED"] },
];

function TrackOrder() {
  const [awbNumber, setAwbNumber] = useState("");
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState("");
  const [activeStage, setActiveStage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const awb = params.get("awb");
    if (awb) {
      setAwbNumber(awb);
      fetchTrackingData(awb);
    }
  }, [location.search]);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`?awb=${awbNumber}`);
    fetchTrackingData(awbNumber);
  };

  const fetchTrackingData = async (awbNumber) => {
    try {
      const response = await fetch(`https://api.atnatic.com/api/bluedart/track`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ awbNumber }),
      });

      if (!response.ok) {
        throw new Error("Invalid waybill number");
      }
      const trackingData = await response.json();
      setTrackingInfo(trackingData);
      setError("");
      
      if (trackingData.ShipmentData && trackingData.ShipmentData.Shipment) {
        const shipment = trackingData.ShipmentData.Shipment[0];
        const scans = shipment.Scans || [];
        
        // Determine the most advanced stage based on scans
        let newActiveStage = 0;
        
        // Sort scans by date and time to get the latest scan
        const sortedScans = scans.sort((a, b) => {
          const dateA = new Date(`${a.ScanDetail.ScanDate} ${a.ScanDetail.ScanTime}`);
          const dateB = new Date(`${b.ScanDetail.ScanDate} ${b.ScanDetail.ScanTime}`);
          return dateB - dateA;
        });
      
        // Find the most recent scan
        if (sortedScans.length > 0) {
          const latestScan = sortedScans[0].ScanDetail;
          
          // Determine stage based on the latest scan
          for (let i = 0; i < stages.length; i++) {
            const stage = stages[i];
            const matchingStatus = stage.status.some(status => 
              latestScan.Scan.trim().toUpperCase().includes(status.trim().toUpperCase())
            );
            
            if (matchingStatus) {
              newActiveStage = i + 1;
            }
          }
        }
      
        setActiveStage(newActiveStage);
      }
    } catch (error) {
      setTrackingInfo(null);
      setError("Invalid waybill number. Please check and try again.");
      setActiveStage(0);
    }
  };

  return (
    <div className="track-order-container">
      <h1 className="track-order-title">TRACK YOUR ORDER</h1>
      <form onSubmit={handleSubmit} className="track-order-form">
        <input
          type="text"
          value={awbNumber}
          onChange={(e) => setAwbNumber(e.target.value)}
          placeholder="Enter AWB Number"
          required
          className="track-order-input"
        />
        <button 
          type="submit" 
          className="track-order-submit-btn"
        >
          TRACK
        </button>
      </form>

      {trackingInfo && trackingInfo.ShipmentData && trackingInfo.ShipmentData.Shipment && (
        <div className="tracking-status-container">
          <h2 className="tracking-status-title">ORDER STATUS</h2>
          <div className="shipment-details">
            <p><strong>AWB Number:</strong> {trackingInfo.ShipmentData.Shipment[0].WaybillNo}</p>
            <p><strong>Status:</strong> {trackingInfo.ShipmentData.Shipment[0].Status}</p>
            <p><strong>ExpectedDelivery:</strong> {trackingInfo.ShipmentData.Shipment[0].ExpectedDelivery}</p>
          </div>
          <div className="tracking-stages">
            {stages.map((stage) => (
              <div 
                key={stage.id} 
                className={`tracking-stage ${
                  stage.id <= activeStage ? 'active' : 'inactive'
                }`}
              >
                <div className={`stage-indicator ${
                  stage.id <= activeStage 
                    ? 'completed' 
                    : stage.id === activeStage 
                    ? 'current' 
                    : ''
                }`}>
                  {stage.id <= activeStage ? '✓' : stage.id}
                </div>
                <div className="stage-details">
                  <p className="stage-name">{stage.name}</p>
                  <p className="stage-description">{stage.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default TrackOrder;
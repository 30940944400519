import React, { forwardRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../Cart/CartContext';
import './Header.css';

const products = [
    {
        id: 1,
        name: 'FIRE T-SHIRT',
        description: 'Description for FIRE T-SHIRT',
        images: ['/Static/ProductImgs/FIRE.jpg', '/Static/ProductImgs/FIRE.jpg', '/Static/ProductImgs/FIRE.jpg', '/Static/ProductImgs/FIRE.jpg'],
        price: 1999,
        keywords: ['fire', 'flame', 'hot', 't-shirt', 'tee','black'],
    },
    {
        id: 2,
        name: 'FLORAL T-SHIRT',
        description: 'Description for FLORAL T-SHIRT',
        images: ['/Static/ProductImgs/FLORAL_SILDE_IMAGES/Main.png', '/Static/ProductImgs/FLORAL_SILDE_IMAGES/Second.png', '/Static/ProductImgs/FLORAL_SILDE_IMAGES/Third.png', '/Static/ProductImgs/FLORAL_SILDE_IMAGES/Fourth.jpeg'],
        price: 1999,
        keywords: ['floral', 'flower', 'nature', 't-shirt', 'tee','black'],
    },
    {
        id: 3,
        name: 'LITM T-SHIRT',
        description: 'Description for LITM T-SHIRT',
        images: ['/Static/ProductImgs/LIVE.jpg', '/Static/ProductImgs/LIVE.jpg', '/Static/ProductImgs/LIVE.jpg', '/Static/ProductImgs/LIVE.jpg'],
        price: 1999,
        keywords: ['litm', 'live', 't-shirt', 'tee','blue','skyblue','sky blue'],
    },
    {
        id: 4,
        name: 'PIGEON T-SHIRT',
        description: 'Description for PIGEON T-SHIRT',
        images: ['/Static/ProductImgs/PIGEON.jpg', '/Static/ProductImgs/PIGEON.jpg', '/Static/ProductImgs/PIGEON.jpg', '/Static/ProductImgs/PIGEON.jpg'],
        price: 1999,
        keywords: ['pigeon', 'bird', 't-shirt', 'tee', 'flying' ,'red'],
    },
];

const Header = forwardRef(({ toggleSideNav }, ref) => {
    const { cartItems } = useContext(CartContext);
    const [showSearch, setShowSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const toggleSearchDropdown = () => {
        setShowSearch(!showSearch);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        const results = products.filter(product =>
            product.name.toLowerCase().includes(query.toLowerCase()) ||
            product.keywords.some(keyword => keyword.includes(query.toLowerCase()))
        );

        setSearchResults(results);
    };

    const calculateTopPosition = () => {
        if (searchResults.length === 0) return '15%';
        return `${15 + (searchResults.length - 1) * 2.5}%`;
    };

    return (
        <header ref={ref}>
            <div className="menu-toggle" onClick={toggleSideNav}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="header-title">
                <Link to="/">
                    <h1 className="header-title">ATNATIC</h1>
                </Link>
            </div>
            <div className="header-links">
                <ul>
                    <li><Link to="/Contact">CONTACT US</Link></li>
                    <li><Link to="/track">TRACK ORDER</Link></li>
                </ul>
            </div>
            <div className="header-icons">
                {!showSearch && (
                    <div onClick={toggleSearchDropdown} className="search-icon">
                        <img src="/Static/Symbols/magnifying-glass.png" alt="Search" />
                    </div>
                )}
                {showSearch && (
                    <div className="search-dropdown" style={{ top: calculateTopPosition() }}>
                        <div className="input-container">
                            <input
                                type="text"
                                className="form-control d-none d-md-block big-screen"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <input
                                type="text"
                                className="form-control d-block d-md-none small-screen mx-auto"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <button className="close-button" onClick={() => setShowSearch(false)}>X</button>
                        </div>
                        {searchResults.length > 0 && (
                            <ul className="search-results">
                                {searchResults.slice(0, 4).map(product => (
                                    <li key={product.id} className="search-result-item">
                                        <Link to={`/product/${product.id}`}>
                                            
                                            <img src={product.images[0]} alt={product.name} className="result-image"  loading="lazy"  />
                                            {product.name}
                                        </Link>
                                    </li>
                              ))}
                             </ul>
                        )}
                    </div>
                )}
                <div className="cart-icon-container">
                    <Link to="/cart">
                        <img src="/Static/Symbols/shopping-bag.png" alt="Cart" />
                        {cartItems.length > 0 && (
                            <span className="cart-counter">
                                {cartItems.length > 9 ? '9+' : cartItems.length}
                            </span>
                        )}
                    </Link>
                </div>
            </div>
        </header>
    );
});

export default Header;

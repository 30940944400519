import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FacebookPixel = () => {
  const location = useLocation();

  useEffect(() => {
    // Track PageView on route change
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, [location]);

  return null; // No need to render noscript tag as it's in index.html
};

export default FacebookPixel; 